/* ==========================================================================
   Standard tags
   ========================================================================== */
html{
  height: 100%;
  -ms-overflow-style: scrollbar;
  overflow-y: scroll;
}
body {
  min-height: 100%;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

html,
body {
  --scrollbar-size : 5px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

body::-webkit-scrollbar {
  width: var(--scrollbar-size);
}

body::-webkit-scrollbar-track {
  background: $gray-400;
  border-radius: var(--scrollbar-size);
}

body::-webkit-scrollbar-thumb {
  border-radius: var(--scrollbar-size);
  background: $gray-600;
}

/**
* Start of workaround for Bootstrap bug where content shifts when modal
* is opened.

* https://github.com/twbs/bootstrap/issues/9855
*/

body.modal-open {
    overflow: hidden;
}

/* Resets */
ul {
  list-style: none;
  padding: 0;
}

[hidden] {
  display: none !important;
}

/* Image
   ========================================================================== */

/* Company Users
   ========================================================================== */
.company-users {
  .action-bar {
    margin-bottom: 10px;

    .sort {
      > a {
        color: $gray-900;
      }

      > a:not(:first-child) {
        margin-left: 15px;
      }
    }
  }

  .edit {
    text-align: right;
  }
}


/* Payment Methods */
.payment-methods {
  .list-group-item {
    padding: 10px 0;
  }
  .expiry {
    color: $gray-700;
  }
}

/* ==========================================================================
   Forms
   ========================================================================== */

/* Input field focus */
textarea, input, div, input:focus {
  outline: none;
}

/* Turn off number input spinners in WebKit. */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Close button
   ========================================================================== */
.close {
  opacity: 1;

  .fa::before , .glyphicons::before{ color: $gray-700; }
  &:hover .fa::before ,&:hover .glyphicons::before { color: $gray-800; }
}

/* Sticky buttons
   ========================================================================== */
.sticky-buttons {
  /* Positioning */
  z-index: 10;

  /* Display & Box Model */
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;

  /* Visual */
  background-color: $white;

  > button {
    /* Display & Box Model */
    margin-bottom: 10px;
  }

  > a[type="button"] {
    /* Display & Box Model */
    margin-bottom: 10px;
  }
}

/* Toggle buttons (http://www.bootstrap-switch.org/)
   ========================================================================== */
.has-switch {
  /* Visual */
  border-radius: 8px;
  border-color: $gray-300;

  /* Display & Box Model */
  min-width: 120px;
  margin-top: -1px;

  > div {
    > label,
    > span {
      /* Display & Box Model */
      padding-bottom: 7px;
      padding-top: 7px;

      /* Visual */
      font-size: 16px;
    }
  }
}

/* Media queries
   ========================================================================== */
@media (min-width: $screen-sm) {
  .copyright {
    text-align: right;
  }
}

/* Buttons
   ========================================================================== */

.button-badge {
  margin-right: -20px;
  float: right;
  margin-top: -10px;
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
}

.btn-github {
  @extend .btn-sm;
}

.rise-admin-only {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &::after {
    content: '';
    // svg path copied from streamlime-icon.component.ts
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'><path fill-rule='evenodd' d='M0 18.0759H13.2019H18V13.2826V0.0938721L0 18.0759ZM18 13.2826H13.2303L13.2019 18.0759L18 13.2826ZM8.63098 17.3667V13.9634H11.8392L8.63098 17.3667ZM13.8549 8.63104V12.0346L17.0631 8.63104H13.8549Z' fill='%2345B764'></path></svg>") center center no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
    margin: 0 0 0 1em;
  }
}

.btn-remove .fa::before,
.btn-remove .glyphicons::before {
  color: $danger;
  font-size: 16px;
}

.btn-all {
  background: $white;
  border-color: $white;

  .fa, .glyphicons {
    margin-top: -5px;
  }
}

.btn-back:hover .fa-back,
.btn-back:hover .glyphicons-back {
  background: url('//s3.amazonaws.com/rise-common-test/images/glyphicons-back-active.png') center no-repeat;
}

/* Sign In button */
.btn-sign-in {
  @extend .btn-success;

  .log_in { color: $white; }
}

.sign-in,.top-auth-button {
  background: none;
  height: 32px;
  line-height: 32px;
  padding: 0;
  border: 0;
  color: $gray-600;
  font-weight: 500;


  &:hover,
  &:active {
    text-decoration: none;
    color: $brand-primary;
  }
}

.top-auth-button {
  img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-left: 3px;
    vertical-align: top;
    margin-top: 2px;
  }
}

 /* SVG CLASSES */

.btn svg {
  width: 20px;
  height: 20px;
  vertical-align: top;
}
.btn-default svg {
  margin-left: 5px;
}

streamline-icon.help {
  @extend .u_clickable;

  svg {
    fill: $gray-500;
    height: 16px;
    width: 16px;
    margin-bottom: -2px;
  }
}

/* Horizontal rule
   ========================================================================== */
.hr-thick {
  border-top: 2px solid $gray-400;
}

.hr-dashed {
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 2px dashed $gray-400;
}

/* Panels
   ========================================================================== */
.stack-md-sideways-panels {
  display: flex;
  flex-flow: column;

  @media (min-width: $screen-md) {
    display: flex;
    flex-flow: row;
  }
}

.stack-sm-sideways-panels {
  display: flex;
  flex-flow: column;

  @media (min-width: $screen-sm) {
    display: flex;
    flex-flow: row;
  }
}

.sideways-panels {
  display: flex;

  .panel {
    flex: 1;
    @extend .u_margin-right;
    &:last-child{
      @extend .u_remove-right;
    }
  }
}

.panel {
  &.panel-thick-shadow {
    box-shadow: 0 10px 20px 0 $shadow-50, 0 6px 6px 0 $shadow-130;
  }
}

/* Other
   ========================================================================== */
.title {
  margin-top: 10px;
  margin-bottom: 0;
}

.dropdown-title {
  color: $gray-800;
  font-weight: bold;
}

.qty {
  width: 40px;
  height: 28px;
}

.danger {
  color: $danger;
}

.text-subtle {
  color: $gray-700;
}

.icon-left { margin-right: 10px; }
.icon-right { margin-left: 10px; }
.icon-sm-left { margin-right: 5px; }
.icon-sm-right { margin-left: 5px; }

.list-separator {
  border-bottom: 1px solid $gray-200;
  width: auto;
}

.btn-google-drive,
.btn-google-drive:active
.btn-google-drive.active {
  width: 30px;
  height: 30px;
  border: none !important;
  background-repeat: no-repeat;
  background-color: transparent !important;
  background-image: url(http://s3.amazonaws.com/Rise-Images/Icons/widget-icon-drive.png);
  @include background-image-retina('http://s3.amazonaws.com/Rise-Images/Icons/widget-icon-drive', 'png', 30px, 30px);
}


.btn-widget-icon-link,
.btn-widget-icon-link:active
.btn-widget-icon-link.active {
  width: 30px;
  height: 30px;
  border: none !important;
  background-repeat: no-repeat;
  background-color: transparent !important;
  background-image: url(http://s3.amazonaws.com/Rise-Images/Icons/widget-icon-link.png);
  @include background-image-retina('http://s3.amazonaws.com/Rise-Images/Icons/widget-icon-link', 'png', 30px, 30px);
}


.btn-widget-icon-storage,
.btn-widget-icon-storage:active
.btn-widget-icon-storage.active {
  width: 30px;
  height: 30px;
  border: none !important;
  background-repeat: no-repeat;
  background-color: transparent !important;
  background-image: url(http://s3.amazonaws.com/Rise-Images/Icons/widget-icon-storage.png);
  @include background-image-retina('http://s3.amazonaws.com/Rise-Images/Icons/widget-icon-storage', 'png', 30px, 30px);
}

.badge-primary {
  background-color: $brand-primary;
}

.badge-beta {
  background-color: $btn-danger-bg;
  cursor: default;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
}

h4 .badge-beta {
  vertical-align: top;
  margin-top: 2px;
}

/* ==========================================================================
   Small devices (tablets, 768px and up)
   ========================================================================== */
