@use "@angular/material" as mat;
/* Variables
   ========================================================================== */

// Colors
$rise-green: #36a860;
$danger: #d9534f;
$google-red: #df4a32;
$rise-premium: #dbf0e0;
$rise-blue: #216ad9;
$google-blue: #4285f4;

$green-100: #dff0d8;
$green-150: #d6e9c6;
$green-300: #1ebb53;

$red-25: #fff5f5;
$red-50: #f8e4e2;
$red-100: #f5b7b7;
$red-200: #e74c3c;
$red-300: #d24726;
$red-400: #cf371f;

$amber-50: #fcf8e3;
$amber-100: #faebcc;

// Tones
$white: #fff;

// Derived from standard tailwind gray tones (however our tailwind config uses this custom palette)
$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #b7bcc5; // Midtone between TW gray-300 and gray-400
$gray-500: #9ca3af; // TW gray-400
$gray-600: #6b7280; // TW gray-500
$gray-700: #585f6b; // https://coolors.co/gradient-palette/6b7280-0d1216?number=6
$gray-800: #454c56;
$gray-900: #333840;
$gray-950: #20252b;
$black: #0d1216;

$border: 1px solid $gray-400;

$microsoft-light-gray: #8C8C8C;
$microsoft-gray: #5E5E5E;
$microsoft-dark-gray: #2F2F2F;

$highlight-color: #FFFFE0;

$shadow-600: rgb(0 0 0 / 0.6);
$shadow-500: rgb(0 0 0 / 0.5);
$shadow-400: rgb(0 0 0 / 0.4);
$shadow-300: rgb(0 0 0 / 0.3);
$shadow-200: rgb(0 0 0 / 0.2);
$shadow-175: rgb(0 0 0 / 0.175);
$shadow-150: rgb(0 0 0 / 0.15);
$shadow-130: rgb(0 0 0 / 0.13);
$shadow-50: rgb(0 0 0 / 0.05);

$mid-shadow-800: rgb(30 30 30 / 0.8);
$mid-shadow-500: rgb(20 20 20 / 0.5);

$light-shadow-500: rgb(50 50 50 / 0.5);
$light-shadow-350: rgb(50 50 50 / 0.35);

$pale-shadow-100: rgb(150 150 150 / 0.1);

$blue-shadow-800: rgb(50 106 191 / 0.8);

$dark-blue-shadow-500: rgb(2 6 32 / 0.5);

$glow-950: rgb(255 255 255 / 0.95);
$glow-800: rgb(255 255 255 / 0.8);
$glow-750: rgb(255 255 255 / 0.75);
$glow-700: rgb(255 255 255 / 0.7);
$glow-600: rgb(255 255 255 / 0.6);
$glow-500: rgb(255 255 255 / 0.5);

$pale-glow-850: rgb(242 242 242 / 0.85);

// Spacing
$title-margin: 6px;

// New Style
$madero-green-active: #2d9653;

// Text
$text-inverse: $white;

// Padding
$pad-sm: .25rem; //4px
$pad-md: .5rem; //8px
$pad-lg: 1rem; //16px

// Shadows
$bottom-shadow: 0 .25rem .25rem $gray-500;
$bottom-shadow-small: 0 0 2px $gray-500;
$vignette-shadow: inset 0 0 3px $gray-500;

// Template Cards
$card-border-radius: 1rem;
$card-image-radius: .5rem;
$card-color: $white;
$card-margin: $pad-md;

//Transitions
$transition-default: .25s ease all;

// Material UI
$mat-input-size: 40px;
$mat-button-size: 40px;
$mat-icon-button-padding: 8px;
$mat-small-button-size: 24px;
$mat-small-icon-size: 16px;
$mat-menu-trigger-padding: 11px;

// Generated using https://supercolorpalette.com/
// https://supercolorpalette.com/?scp=G0-lch-8BB4FF-9DC1FF-AFD0FF-C0DEFF-D2ECFF-E4FAFF (light colors)
// https://supercolorpalette.com/?scp=G0-lch-276BD9-4E82EE-6D9AFF-8AB3FF (mid colors)
// https://supercolorpalette.com/?scp=G0-lch-276BD9-2364CC-205DBE-1C56B1 (dark colors)
// 600 is $rise-blue
$rise-blue-palette: (
  50: #E4FAFF,
  100: #D2ECFF,
  200: #AFD0FF,
  300: #8AB3FF,
  400: #6D9AFF,
  500: #4E82EE,
  600: $rise-blue,
  700: #2364CC,
  800: #205DBE,
  900: #1C56B1,
  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white
  )
);

// Generated using https://supercolorpalette.com/
// https://supercolorpalette.com/?scp=G0-lch-33A75F-55B373-70BE86-8ACA9A-A2D5AE-BAE1C3-D2ECD7-EAF7ED (light colors)
// https://supercolorpalette.com/?scp=G0-lch-33A75F-2F9655-2B844C-267342-226339-1D5330 (dark colors)
// 400 is $rise-green
$rise-green-palette: (
  50: #EAF7ED,
  100: #D2ECD7,
  200: #A2D5AE,
  300: #70BE86,
  400: $rise-green,
  500: #2F9655,
  600: #2B844C,
  700: #267342,
  800: #226339,
  900: #1D5330,
  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white
  )
);

$rise-red-palette: (
  50: #FCEDEE,
  100: #F7D8DA,
  200: #F0B1B6,
  300: #E98A92,
  400: #E16A73,
  500: #E11D48,
  600: #D24A46,
  700: #CC403D,
  800: #C53734,
  900: #BE2D2B,
  contrast: (
    50: #0d1216,
    100: #0d1216,
    200: #0d1216,
    300: #0d1216,
    400: #0d1216,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff
  )
);

$rise-primary: mat.m2-define-palette($rise-green-palette, 400);
$rise-accent: mat.m2-define-palette($rise-blue-palette, 600);
$rise-danger: mat.m2-define-palette($rise-red-palette, 500);
