.social-media-posts-container,
.social-media-followers-container {

  .page-body {
    height: calc(var(--scrollable-component-body-height) - 64px);
  }

  .page-body-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .feed-selector-container {
    margin-bottom: 32px;
  }

  .add-feed-name {
    font-size: 16px;
    padding-top: 16px;
  }

  .add-feed-title {
    font-size: 18px;
    font-weight: bold;
    padding-top: 20px;
  }

  .add-feed-disclaimer {
    font-size: 16px;
    padding-top: 16px;
  }

  .add-feed-button {
    --mdc-outlined-button-label-text-color: #{$gray-dark};
  }

  .btn-connect-facebook {
    background-color: #1875F2;
    border-color: #1877F2;
    color: white;
  }

  .btn-connect-instagram {
    border-radius: 4px;
    background: linear-gradient(90deg, #F40001 0%, #B900B4 100%);
    border-color: #F40001;
    color: white;
  }

  .icon-connect {
    display: inline-table;
    height: 20px;
  }

  .select-page-title {
    font-size: 18px;
    font-weight: bold;
  }

  .select-page-radio-group {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    align-items: flex-start;
  }

  .select-page-radio-button {
    margin: 1px;
  }

  .select-page-picture {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .layout-container {
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }

  .layout-option {
    flex: 1 1 0;
    padding: 16px 0;
    border-radius: 6px;
    border: 2px solid $gray-300;
    background-color: transparent;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &--selected {
      border: 2px solid $rise-blue;
    }
  }

  .layout-icon-container {
    padding: 0 24px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .layout-label {
    align-self: stretch;
    opacity: 0.7;
    text-align: center;
    color: #020620;
    font-size: 13px;
    font-family: Inter;
    font-weight: 400;
    line-height: 18.2px;
  }
}

.instagram-svg-defs {
  width: 0px !important;
  height: 0px !important;
  position: absolute !important;
}
