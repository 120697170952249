/* Modal scrollable list
   ========================================================================== */
.scrollable-list {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 240px);
  min-height: 240px;
}

.scrollable-list::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scrollable-list::-webkit-scrollbar:vertical {
  width: 11px;
}

.scrollable-list::-webkit-scrollbar:horizontal {
  height: 11px;
}

.scrollable-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid $gray-100;
  /* should match background, can't be transparent */
  background-color: $light-shadow-500;
}

.scrollable-list::-webkit-scrollbar-track {
  background-color: $gray-100;
  border-radius: 2px;
}


/* List-Group
   ========================================================================== */


/* List-Group
   ========================================================================== */

.list-group {
  margin-bottom: 0;
}

.list-group-item {
  background-color: none;
  border: none;
}

.select-subcompany-modal .list-group {
  @extend .panel;
}

/* rvSortable List
   ========================================================================== */
.draggable-mirror {
  left: 10px !important;
  top: 10px !important;
}