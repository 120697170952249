.modal-dialog {
  max-width: 1140px;
  margin: 10px auto;
}

.modal-content {
  margin: 0 10px;
}

.upgrade-modal {
  .modal-content {
    border: none;
  }
}

.modal-custom > div, .modal-custom.modal-dialog {
    width: auto;
    display: table;
    .modal-content {
      p {
        display: inline-block;
        white-space: nowrap;
      }
    }
  @media (max-width: $screen-xs) {
    display: block;
    .modal-content {
      p {
        white-space: normal;
      }
    }
  }
}

.modal-dialog .sign-in,
.modal-dialog .sign-in:hover {
  @extend .btn-sign-in;
  float: left;
  .fa::before, .glyphicons::before {
    line-height: 30px;
  }
}

iframe.full-screen {
  border: none;
  margin: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.modal-full {
  max-width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  overflow: hidden;
  .modal-dialog {
    max-width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 0;
  }
}

.modal-small-footer {
  margin-bottom: 0;
  background: $gray-100;
  overflow: hidden;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid $gray-200;
  min-height: 16.43px;
  width: 100%;
  img {
    width: auto;
    height: 16px;
    position: relative;
    top: -2px;
    margin-left: 5px;
  }
}

.storage-modal {
  padding: 15px;
}

body>div.modal, .max-z-index {
  z-index: 9999 !important;
}

modal-container.modal {
  overflow-y: auto;
}

modal-container, .madero-style {
  .cdk-visually-hidden {
    display: none;
  }

  &.modal::before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
    margin-right: -4px; /* Adjusts for small screens by removing the space added by inline-block */
  }

  &.modal {
    text-align: center;

    & .modal-dialog {
      text-align: left;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.madero-style, modal-container .madero-style {
  &.modal .modal-dialog, &.modal-dialog {
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    & .modal-dialog, &.modal-dialog {
      width: 460px;

      &.modal-sm {
        width: 365px;
      }

      &.modal-md {
        width: 580px;
      }

      &.modal-lg {
        width: 100%;

        &.product-preview-modal {
          width: 70%;
          margin-top: 42px;
        }
      }
    }
  }
}
