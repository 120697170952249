.workspace {
  --sidebar-width: 340px;
  --toolbar-height : 62px;
  --toolbar-margin: 20px;
  --footer-height : 60px;
  --common-header-height : 90px;
  --sidebar-displace : -340px;
  // Edge currently has issues using calculated variables in transitions
  // --sidebar-displace : calc(var(--sidebar-width)*-1);
  &.hide-sidebar {--sidebar-width: 0px;}
  --total-height:  calc(var(--toolbar-height) + var(--toolbar-margin) + var(--footer-height) + var(--common-header-height));
  height: calc(100vh - var(--total-height) );
  margin: 0 var(--toolbar-margin) 0;
}

@media (max-width: $screen-md) {
  .workspace {
    --toolbar-height : 62px;
    --footer-height : 80px;
  }
}

@media (max-width: $screen-sm) {
  .workspace {
    --toolbar-height : 124px;
    --footer-height : 174px;
  }
}

.workspace-wrapper {
  display: flex;
  flex-flow: column;
}

.workspace-board {
  display: none;
  background-color: $white;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 80;
  width: 100%;
  height: calc(100vh - var(--total-height) );
  -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  perspective: 800px;
}

@media (min-width: $screen-sm) {
  .workspace-wrapper {
    display: flex;
    flex-flow: row;
    height: 100%;
  }
  .workspace-board {
    display: block;
  }
}

.workspace-sidebar {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  flex-grow: 1;
  width: var(--sidebar-width);
  height: 100%;
  margin: 0 auto;
  padding: 10px 0 10px 0;

  & .component-header {
    padding-bottom: 10px;
    margin: 0;
  }

  .attribute-editor-component {
    margin-top: 10px;
  }

  .rise-playlist-container .templates-row {
    margin: 0px;
  }

}

.workspace-sidebar-content {
  width: var(--sidebar-width);
}

@media (min-width: $screen-sm) {
  .workspace-sidebar-content {
    width: calc( 2 * var(--sidebar-width) );
  }
}

.ph-list {
  --sidebar-body-displace : 75px;
}

.ph-settings {
  --sidebar-body-displace : 112px;
}

.ph-playlist {
  --sidebar-body-displace : 160px;
}

.workspace-sidebar-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - var(--sidebar-body-displace) - var(--total-height));
}

.workspace-sidebar-body::-webkit-scrollbar {
    --base-size : 5px;
    width: var(--base-size);
}
.workspace-sidebar-body::-webkit-scrollbar-track {
    background: $gray-400;
    border-radius: var(--base-size);
}
.workspace-sidebar-body::-webkit-scrollbar-thumb {
    border-radius: var(--base-size);
    background: $gray-600;
}

.workspace-toolbar {
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid $gray-500;
  padding: 18.5px 0px;

  .preview-button {
    @extend .ml-0;
  }

  .presentation-name {
    max-width: calc(100vw - 240px);
    margin: 0;
    padding: 0;

    .input-stretchy {
      font-size: 24px;
      line-height: 32px;
      @media (max-width: $screen-sm) {
        font-size: 18px;
      }
    }
  }

  .streamline-component-icon {
    margin: 0;
  }

}

@media (max-width: $screen-md) {
  .workspace-toolbar {
    .presentation-name {
      max-width: calc(100vw - 240px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

@media (max-width: $screen-sm) {
  .workspace-toolbar {
    flex-wrap: wrap;
    .presentation-name {
      max-width: calc(100vw - 250px);
    }
    .preview-button {
      margin: 10px 0;
      display: block;
      width: 100%;
    }
  }
}

.ph-list, .ph-properties {
  width: var(--sidebar-width);
}

.workspace {
  .workspace-sidebar {

    .table-playlist-items {
      tbody>tr>td {
        height: 56px;
      }
    }
    .ph-list,
    .ph-properties {
      padding: 0 10px 0 0;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }
    .ph-properties {
      float: right;
      opacity: 0;
      @media (max-width: $screen-sm) {
        -webkit-transform: translateX(0);
        /* Safari */
        transform: translateX(0);
        float: none;
        display: none;
      }
    }
    &.show-ph-properties {

      .ph-list {
        -webkit-transform: translateX(var(--sidebar-displace));
        /* Safari */
        transform: translateX(var(--sidebar-displace));
        @media (max-width: $screen-sm) {
          -webkit-transform: translateX(0);
          /* Safari */
          transform: translateX(0);
          display: none;
        }
      }
      .ph-properties {
        opacity: 1;
        -webkit-transform: translateX(var(--sidebar-displace));
        /* Safari */
        transform: translateX(var(--sidebar-displace));
        @media (max-width: $screen-sm) {
          -webkit-transform: translateX(0);
          /* Safari */
          transform: translateX(0);
          display: block;
        }
      }
    }

  }  //END sidebar

  &.hide-sidebar {

    .workspace-sidebar {
      -webkit-transform: translateX(var(--sidebar-displace));
      transform: translateX(var(--sidebar-displace));
    }

    @media (max-width: $screen-sm) {
      .workspace-sidebar {
        -webkit-transform: translateX(-100%);
        /* Safari */
        transform: translateX(-100%);
      }
    }
  }
}

.workspace .workspace-sidebar, #playlistItemModal {
  .timeline-label, .distribution-label {
    display: none;
  }
}


.placeholder-item-name {
  @extend .u_ellipsis-md;
  width: 180px;
  font-weight: 500;
  line-height: 24px;
  &:hover {
    color: $rise-blue;
  }
}

.editor-footer {
  width: 100%;
  z-index: 100;
  border-top: 1px solid $gray-500;
  padding: 10px 0px;

  .button-row {
    margin: 0;
  }
}

.ph-block {
  position: absolute;
  border: 1px solid $white;
  cursor: pointer;
  -webkit-box-shadow: inset 0px 0px 56px 0px $shadow-150;
  -moz-box-shadow: inset 0px 0px 56px 0px $shadow-150;
  box-shadow: inset 0px 0px 56px 0px $shadow-150;
  img {
    width: 100%;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid $shadow-500;
  }
  &:after {
    -webkit-transition: all 1s;
    /* Safari 3.1 to 6.0 */
    transition: all 1s;
    background-color: $mid-shadow-500;
    content: 'Edit';
    color: $white;
    line-height: 40px;
    text-align: center;
    height: 40px;
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;
    opacity: 0;
  }
  &:hover {
    border-color: $rise-green;
    &:after {
      opacity: 1;
    }
  }
  .ph-name {
    position: absolute;
    left: 3px;
    top: 3px;
    z-index: 9999;
    line-height: 30px;
    color: $white;
    display: inline-block;
    padding: 0 10px;
    border-radius: 5px;
    font-weight: 600;
    background: $light-shadow-350;
    text-shadow: -1px -1px 0 $gray-700, 1px -1px 0 $gray-700, -1px 1px 0 $gray-700, 1px 1px 0 $gray-700;
  }
  &.edit-mode {
    -webkit-box-shadow: 0px 0px 0px 10000px $shadow-300;
    -moz-box-shadow: 0px 0px 0px 10000px $shadow-300;
    box-shadow: 0px 0px 0px 10000px $shadow-300;
    &:after {
      opacity: 1;
      content: 'Editing';
    }
  }
}

.cm-s-twilight.CodeMirror {
  position: relative;
  height: 100%;

  .CodeMirror-hscrollbar {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid $gray-700;
      /* should match background, can't be transparent */
      background-color: $mid-shadow-800;
    }
    &::-webkit-scrollbar-track {
      background-color: $gray-700;
      border-radius: 2px;
    }
  }

  .CodeMirror-vscrollbar {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
      width: 11px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid $gray-700;
      /* should match background, can't be transparent */
      background-color: $mid-shadow-800;
    }
    &::-webkit-scrollbar-track {
      background-color: $gray-700;
      border-radius: 2px;
    }
  }
}

.ph-transform {
  .input-group {
    .form-control {
      padding-left: 4px;
      padding-right: 4px;
    }
    .input-group-addon {
      padding: 0 4px;
      font-size: 11px;
      &:first-child {
        width: 50px;
      }
      &:last-child {
        font-size: 11px;
      }
    }
  }
}

.artboard-presentation {
  position: absolute;
  width: 80%;
  .edit-mode .resize {
    position: absolute;
    width: 16px;
    height: 16px;
    background: $white;
    color: $white;
    z-index: 9999;
    border-radius: 8px;
    border: 2px solid $gray-400;
        &.n-resize {
            top: -8px;
            left: calc(50% - 8px);
            cursor: n-resize;
        }
        &.e-resize {
            top: calc(50% - 8px);
            left: calc(100% - 8px);
            cursor: e-resize;
        }
        &.s-resize {
            top: calc(100% - 8px);
            left: calc(50% - 8px);
            cursor: s-resize;
        }
        &.w-resize {
            top: calc(50% - 8px);
            left: -8px;
            cursor: w-resize;
        }
        &.nw-resize {
            top: -8px;
            left: -8px;
            cursor: nw-resize;
        }
        &.ne-resize {
            top: -8px;
            left: calc(100% - 8px);
            cursor: ne-resize;
        }
        &.se-resize {
            top: calc(100% - 8px);
            left: calc(100% - 8px);
            cursor: se-resize;
        }
        &.sw-resize {
            top: calc(100% - 8px);
            left: -8px;
            cursor: sw-resize;
        }
    }
}


.html-editor {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 80;
  background: $white;
  height: 100%;
  width: 100%;
  padding-top: 0px;
  pre {
    border: 0;
    background: $gray-950;
    color: $rise-green;
    margin: 0;
    font-size: 14px;
    -webkit-text-stroke: 0.25px;
  }
}

.workspace {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 11px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid $gray-100;
    /* should match background, can't be transparent */
    background-color: $light-shadow-500;
  }
  &::-webkit-scrollbar-track {
    background-color: $gray-200;
    border-radius: 2px;
  }
}

#artboard {
  border: 10px solid transparent;
  -webkit-transition: all .4s;
  transition: all .4s;

  >div {
    background-color: $white;
  }
}

.workspace-sidebar-content {
  .image-placeholder {
    width: auto;
    height: 128px;
  }
}

.ph-item-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  background: $shadow-200;
  &:before {
    font-family: FontAwesome,Arial,Helvetica,sans-serif;
    content: '\f009 \00a0' attr(data-before);
    border-radius: 10px;
    background: $shadow-400;
    top: 50%;
    left: 50%;
    position: absolute;
    width: auto;
    height: auto;
    line-height: 30px;
    padding: 2px 17px;
    font-size: 16px;
    color: $glow-600;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  &.ph-video-item {
    &:before {
      content: '\f03d \00a0' attr(data-before);
    }
  }
  &.ph-embedded-item {
            &:before {
                width: 47px;
                height: 45px;
                padding: 6px 12px;
                line-height: 41px;
                content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNjAgNTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDYwIDUyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZmlsbD0id2hpdGUiIGZpbGwtb3BhY2l0eT0iMC42IiBkPSJNNTMuMSwySDYuN0MzLjgsMiwxLjQsNC4zLDEuNCw3LjJ2MzcuOGMwLDIuOSwyLjMsNS4yLDUuMiw1LjJoNDYuNWMyLjksMCw1LjItMi4zLDUuMi01LjJWNy4yQzU4LjQsNC4zLDU2LDIsNTMuMSwyeiBNMTAuNCwzNS4xbDkuNS0xOC45bDkuNSwxOC45SDEwLjR6IE00OC44LDM0LjlIMzUuMWMtMC43LDAtMS4zLTAuNi0xLjMtMS4zYzAtMC43LDAuNi0xLjMsMS4zLTEuM2gxMy43YzAuNywwLDEuMywwLjYsMS4zLDEuM0M1MC4xLDM0LjMsNDkuNSwzNC45LDQ4LjgsMzQuOXogTTQ4LjgsMjcuNEgzNS4xYy0wLjcsMC0xLjMtMC42LTEuMy0xLjNjMC0wLjcsMC42LTEuMywxLjMtMS4zaDEzLjdjMC43LDAsMS4zLDAuNiwxLjMsMS4zQzUwLjEsMjYuOCw0OS41LDI3LjQsNDguOCwyNy40eiBNNDguOCwxOS44SDM1LjFjLTAuNywwLTEuMy0wLjYtMS4zLTEuM3MwLjYtMS4zLDEuMy0xLjNoMTMuN2MwLjcsMCwxLjMsMC42LDEuMywxLjNTNDkuNSwxOS44LDQ4LjgsMTkuOHoiLz48L2c+PC9zdmc+);
            }
  }
}

.btn-group-justified {
  margin-bottom: 5px;
  a span {
    font-size: 11px;
    display: block;
    text-align: center;
    line-height: 15px;
  }
  a svg {
    height: 33px;
    width: 30px;
    margin-right: 2px;
    & path {
      fill: $gray-900;
    }
  }
}

.playlist-item-thumbnail {
  overflow: hidden;
  height: 44px;
  width: 44px;
  border: 1px solid $gray-400;
  border-radius: 4px;
  float: left;
  margin-right: 10px;
}

.playlist-item-thumbnail img {
  width: 100%;
}

.playlist-item-duration {
  color: $rise-blue;
}

.name-container {
  margin-top: 6px;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  span {
    vertical-align: middle;
  }
  p:first-child {
    cursor: pointer;
    span:first-child {
      width: 16px;
      height: 16px;
      display: inline-block;
    }
    span:last-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 125px;
      display: inline-block;
      line-height: 16px;
    }
  }
  svg path {
    fill: $rise-blue;
  }
  p span {
    color: $rise-blue;
    &:hover {
      text-decoration: underline;
    }
  }
}



